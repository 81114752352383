var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "clock" }, [
    !_vm.options.hideDate
      ? _c("div", { staticClass: "upper" }, [
          _c("p", { staticClass: "city" }, [_vm._v(_vm._s(_vm.cityName))]),
          _c("p", { staticClass: "date" }, [_vm._v(_vm._s(_vm.date))]),
        ])
      : _vm._e(),
    _c("p", { staticClass: "time" }, [_vm._v(_vm._s(_vm.time))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }